import RectCheckboxGroup from './RectCheckboxGroup'
import styled, {css} from 'styled-components'
import {RectOptionInterface} from '../../../utils/interfaces'
import GroupLabel from '../GroupLabel'
import {solease_error_red} from "../../../assets/Colors";

const Wrapper = styled.div`
    margin: 24px 0;
`

const OptionsWrapper = styled('div')<{
    center: boolean
    error?: boolean
    flipOrderMobile?: boolean
    maxWidth?: number
}>`
    display: flex;
    gap: 12px;
    flex-direction: column;
    align-items: center;
    padding: 14px 0;
    border-radius: 4px;
    border: 1px solid ${props => props.error ? solease_error_red : 'transparent'};

    ${props =>
            props.maxWidth &&
            css`
                max-width: ${props.maxWidth + 'px' || 'unset'};
                margin: auto;
            `};
    @media (max-width: 600px) {
        gap: 15px;
        ${props =>
                props.flipOrderMobile &&
                css`
                    div:first-child {
                        order: 3;
                    }
                `}
    }
`

interface OptionGroupControlInterface<T> {
    options: T[]
    singleChoice?: boolean
    small?: boolean
    center?: boolean
    flipOrderMobile?: boolean
    maxWidth?: number
    error?: boolean
    label?: {
        description: string
        description2?: string
        tooltip?: string
    }

    onChange(options: T[]): void
}

const RectCheckboxControl = <T extends RectOptionInterface>(
    props: OptionGroupControlInterface<T>
) => {
    const {
        label,
        onChange,
        options,
        center,
        small,
        maxWidth,
        error
    } = props

    const onOptionClicked = (selectedOption: T) => {
        const optionsBuffer = options.map(option => ({
            ...option,
            active:
                option === selectedOption
                    ? !option.active
                    : option.active
        }))
        onChange(optionsBuffer)
    }

    if (options.length)
        return (
            <Wrapper>
                {label && (
                    <GroupLabel
                        label={label.description}
                        tooltip={label.tooltip}
                    />
                )}
                {label?.description2 && (
                    <p style={{fontSize: '14px', textAlign: 'left'}}>
                        {label.description2}
                    </p>
                )}
                <OptionsWrapper
                    center={!!center}
                    maxWidth={maxWidth}
                    error={error}
                >
                    {options.map((option, index) => (
                        <RectCheckboxGroup
                            id={option.id}
                            imgSrc={option.imgSrc}
                            small={small}
                            key={index}
                            description={option.description}
                            active={!!option.active}
                            onClick={() => onOptionClicked(option)}
                        />
                    ))}
                </OptionsWrapper>
            </Wrapper>
        )
    else return <></>
}

export default RectCheckboxControl
