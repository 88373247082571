import React, {useContext} from 'react';
import {MainFrame} from "./UI/components/MainFrame";
import ProgressBar from "./UI/components/ProgressBar";
import Step1 from "./UI/steps/Step1";
import {StepperContext} from "./utils/Context/StepperContext";
import Step4 from "./UI/steps/Step4";
import Step2 from "./UI/steps/Step2";
import Step3 from "./UI/steps/Step3";
import DataContextProvider from "./utils/Context/DataContext";
import LanguageContextProvider from "./utils/Context/LanguageContext";

function App() {

    const {step} = useContext(StepperContext)
    return (
        <LanguageContextProvider>
            <MainFrame>
                <DataContextProvider>
                    <ProgressBar step={step}/>
                    {
                        step === 1
                            ? <Step1/>
                            : step === 2
                                ? <Step2/>
                                : step === 3
                                    ? <Step3/>
                                    : <Step4/>
                    }
                </DataContextProvider>
            </MainFrame>  
        </LanguageContextProvider>
    );
}

export default App;
