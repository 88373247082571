export const solease_mushroom_risotto = '#DAD0CA'
export const solease_gray = '#777777'
export const solease_dark_gray = '#666666'

export const solease_cultured = '#F8F8F8'

export const solease_gray_2 = '#C4C4C4'

export const solease_error_red = '#FF4545'

export const solease_misty_dove = '#c2bfbf'

export const solease_green = '#538F74'

export const solease_default_text = '#333333'

export const progress_bar_inactve = '#E8EFF1'
