export const arrowIcon = require('./icons/arrow_icon.svg').default
export const checkboxChecked =
    require('./icons/checkbox_checked.svg').default
export const checkedIcon = require('./icons/checkedIcon.svg').default
export const discussWishesImg =
    require('./icons/discuss_wishes.svg').default
export const questionMark = require('./icons/question_mark.svg').default
export const infoIcon = require('./icons/info.svg').default
export const receiveQuoteImg = require('./icons/receive_quote.svg').default
export const sharpenAdviceImg =
    require('./icons/sharpen_advice.svg').default
export const frownIcon =
    require('./icons/frown.svg').default

