import styled from 'styled-components'
import {OptionInterface} from '../../utils/interfaces'
import GroupLabel from './GroupLabel'
import {useContext} from "react";
import {LanguageContext} from "../../utils/Context/LanguageContext";
import {Checkbox, Text} from "./Shared";
import {solease_error_red} from "../../assets/Colors";

export const CheckboxControlWrapper = styled.div`
    margin: 24px 0;
`

const OptionsWrapper = styled('div')<{error?: boolean}>`
    display: flex;
    flex-direction: column;
    margin-top: 14px;
    padding-left: 30px;
    border-radius: 4px;
    border: 1px solid ${props => props.error ? solease_error_red : 'transparent'};
    
    div{
        height: 40px;
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        
        ${Checkbox}{
            width: 20px;
            height: 20px;
        }
    }
`

interface OptionGroupControlInterface<T> {
    options: T[]
    singleChoice?: boolean
    small?: boolean
    center?: boolean
    flipOrderMobile?: boolean
    maxWidth?: number
    error?: boolean
    label?: {
        description: string
        description2?: string
        tooltip?: string
    }

    onChange(options: T[]): void
}

const CheckboxControl = <T extends OptionInterface>(
    props: OptionGroupControlInterface<T>
) => {
    const {
        label,
        onChange,
        options,
        error
    } = props

    const {strings} = useContext(LanguageContext)

    const onOptionClicked = (selectedOption: T) => {
        const optionsBuffer = options.map(option => ({
            ...option,
            active:
                option === selectedOption
                    ? !option.active
                    : option.active
        }))
        onChange(optionsBuffer)
    }

    if (options.length)
        return (
            <CheckboxControlWrapper>
                {label && (
                    <GroupLabel
                        label={label.description}
                        tooltip={label.tooltip}
                    />
                )}
                {label?.description2 && (
                    <p style={{fontSize: '14px', textAlign: 'left'}}>
                        {label.description2}
                    </p>
                )}
                <OptionsWrapper error={error}>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            onClick={() => !option.disabled && onOptionClicked(option)}>
                            <Checkbox active={option.active} disabled={option.disabled}/>
                            <Text>{strings[option.description]}</Text>
                        </div>
                    ))}
                </OptionsWrapper>
            </CheckboxControlWrapper>
        )
    else return <></>
}

export default CheckboxControl
