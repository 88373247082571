import styled from "styled-components";
import {progress_bar_inactve, solease_green} from "../../assets/Colors";
import {useContext} from "react";
import {DataContext} from "../../utils/Context/DataContext";

const Wrapper = styled.div`
    border: 2px solid ${progress_bar_inactve};
    border-radius: 40px;
    width: 190px;
    display: flex;
    margin: auto;
    height: 36px;
    box-sizing: border-box;
`

const SwitchButton = styled('div')<{ active: boolean }>`
    font-size: 14px;
    font-family: Quicksand, sans-serif;
    font-weight: ${props => props.active ? 600 : 500};
    color: ${props => props.active ? solease_green : 'inherit'};
    box-sizing: border-box;
    display: flex;
    min-width: 100px;
    height: 36px;
    margin-top: -2px;
    margin-left: -2px;
    padding: 5px 15px;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    border: 2px solid ${props => props.active ? solease_green : 'transparent'};
    cursor: pointer;
    transition: border .3s;
    
    &:nth-of-type(1){
        margin-right: -5px;
    }

    &:nth-of-type(2){
        margin-left: -5px;
    }
`

const FormTypeSwitch = () => {

    const {isMkb, setIsMkb} = useContext(DataContext)

    return <Wrapper>
        <SwitchButton
            onClick={()=>setIsMkb(false)}
            active={!isMkb}> Particulier </SwitchButton>
        <SwitchButton
            onClick={()=>setIsMkb(true)}
            active={isMkb}> Zakelijk </SwitchButton>
    </Wrapper>
}

export default FormTypeSwitch