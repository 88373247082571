import {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react'
import {getRequest, postRequest} from "../Api";
import {extractAdditionFromHouseNumber} from "../helperFunctions";
import {AxiosError} from "axios";
import {MainFrameContext} from "../../UI/components/MainFrame";
import {OptionInterface, PersonalInfoInterface} from "../interfaces";
import {StepperContext} from "./StepperContext";
import Cookies from "js-cookie";

interface ServiceDataInterface {
    options: OptionInterface[]
    insulation: OptionInterface[]
    contractOptions: OptionInterface[]
    heatingType: string
    houseType: string
    consumption?: number
    comments: string
}

interface DataContextInterface {
    location: any
    houseOwner: string
    isMkb: boolean

    setHouseOwner(houseOwner: string): void

    setLocationData(location: string, postCode: string): void

    submitOfferForm(personalInfo: PersonalInfoInterface, companyName: string): void

    step2Submit(serviceData: ServiceDataInterface): void

    setIsMkb(isMkb: boolean): void
}

export const DataContext = createContext({} as DataContextInterface)

const DataContextProvider = (props: { children: ReactNode }) => {

    const [location, setLocation] = useState({postcode: '', number: '', street: '', city: ''})
    const [houseOwner, setHouseOwner] = useState('')
    const [serviceData, setServiceData] = useState<ServiceDataInterface>()
    const [leadsource, setLeadsource] = useState('179')
    const [isMkb, setIsMkb] = useState(false)
    const {triggerErrorPopup, setPending} = useContext(MainFrameContext)
    const {dispatchStep} = useContext(StepperContext)

    useEffect(() => {
        const gfMedium = Cookies.get('gf_medium_parameter')
        gfMedium && setLeadsource(gfMedium)
    }, [])

    const setLocationData = useCallback(
        (address: string, postCode: string) => {
            setPending(true)
            const [houseNumber, addition] =
                extractAdditionFromHouseNumber(address)

            getRequest(
                '/housescan/insulation/address',
                {
                    postcode: postCode,
                    number: houseNumber,
                    addition
                },
                (data: any) => {
                    const {address} = data
                    setLocation({
                        number: houseNumber + (addition ? addition : ''),
                        postcode: postCode,
                        street: address.split(' ')[0],
                        city: address.split(',')[1].split(' ')[2]
                    })
                    setPending(false)
                },
                (err: AxiosError) => {
                    if (err.response?.status === 404) {

                    } else triggerErrorPopup()
                    setPending(false)
                }
            )
        },
        [triggerErrorPopup, setPending]
    )

    const step2Submit = (serviceData: ServiceDataInterface) => {
        setServiceData(serviceData)
        dispatchStep('next')
    }

    const submitOfferForm = (personalInfo: PersonalInfoInterface, companyName?: string) => {

        if (serviceData) {
            setPending(true)
            const solar = serviceData.options.filter(option => option.id === 'solar')[0]
            const heatpump = serviceData.options.filter(option => option.id === 'heatpump')[0]
            const battery = serviceData.options.filter(option => option.id === 'homebattery')[0]
            const insulation = serviceData.options.filter(option => option.id === 'insulation')[0]
            const chargingStation = serviceData.options.filter(
                option => option.id === 'charging_station'
            )[0]
            const contractType = serviceData.contractOptions.find(item => item.id === 'rent')?.active && serviceData.contractOptions.find(item => item.id === 'purchase')?.active ? '3'
                : serviceData.contractOptions.find(item => item.id === 'rent')?.active ? '1' : '2'
            const insulationObject = serviceData.insulation.reduce((acc, item) => {
                acc[item.id] = !!item.active;
                return acc;
            }, {} as { [key: string]: boolean });

            const mkbFields = {
                companyname: companyName,
                jaarverbruik: serviceData.consumption || 0
            }

            const body = {
                ...personalInfo,
                ...location,
                ...(isMkb ? mkbFields : {}),
                solar: solar.active,
                chargingStation: chargingStation.active,
                battery: battery.active,
                heatpump: heatpump.active ? {
                    heatingType: serviceData.heatingType,
                    houseType: serviceData.houseType
                } : false,
                contractType,
                isolatie: insulation.active ? insulationObject : false,
                comments: serviceData.comments,
                leadsource
            }

            postRequest(process.env.REACT_APP_NETSUITE_URL || '', body, () => {
                dispatchStep('next')
                setPending(false)
            }, (e: AxiosError) => {
                console.log(e)
                setPending(false)
            })
        }
    }

    return (
        <DataContext.Provider
            value={{
                location,
                setLocationData,
                houseOwner,
                step2Submit,
                setHouseOwner,
                submitOfferForm,
                isMkb,
                setIsMkb
            }}
        >
            {props.children}
        </DataContext.Provider>
    )
}

export default DataContextProvider
