export const energyOptions = [
    {
        id: 'solar',
        description: 'service_solar_description',
        imgSrc: require('../assets/icons/solar_panels_2.svg').default,
    },
    {
        id: 'homebattery',
        description: 'service_homebattery_description',
        imgSrc: require('../assets/icons/home_battery_2.svg').default,
    },
    {
        id: 'charging_station',
        description: 'service_chargingstation_description',
        imgSrc: require('../assets/icons/charging_station_2.svg').default,
    },
    {
        id: 'heatpump',
        description: 'service_heatpump_description',
        imgSrc: require('../assets/icons/water_pump.svg').default,
    },
    {
        id: 'insulation',
        description: 'service_insulation_description',
        imgSrc: require('../assets/icons/cavity_insulation_2.svg').default,
    },
]

export const insulationOptions = [
    {
        id: 'glass',
        description: 'service_glass_description',
        imgSrc: require('../assets/icons/glass_insulation_2.svg').default,
    },
    {
        id: 'wall',
        description: 'service_wall_description',
        imgSrc: require('../assets/icons/cavity_insulation_2.svg').default,
    },
    {
        id: 'roof',
        description: 'service_roof_description',
        imgSrc: require('../assets/icons/roof_insulation_2.svg').default,
    },
    {
        id: 'ground',
        description: 'service_ground_description',
        imgSrc: require('../assets/icons/floor_insulation_2.svg').default,
    }
]