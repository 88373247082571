import styled from 'styled-components'
import {progress_bar_inactve, solease_green} from '../../assets/Colors'

const Wrapper = styled.div`
    position: relative;
    margin: auto;
    margin-bottom: 45px;
    background: white;
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
`

const Circle = styled('div')<{ active?: boolean, passed?: boolean }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid ${props => (props.active || props.passed) ? solease_green : progress_bar_inactve};
    background-color: ${props => props.passed ? solease_green : 'transparent'};
    transition: all 1s;

`

const ProgressBar = (props: { step: number }) => {
    const {step} = props

    return (
        <Wrapper>
            {
                [1, 2, 3].map((i) =>
                    <Circle
                        key={i}
                        active={step === i}
                        passed={step > i}/>)
            }
        </Wrapper>
    )
}

export default ProgressBar
