import styled from "styled-components";
import {solease_green} from "../../assets/Colors";

const Wrapper = styled.div`
    margin-bottom: 10px;
    border-bottom: 2px solid ${solease_green};
    text-align: left;
    font-size: 14px;
    font-family: Quicksand, sans-serif;
`

const Divider = (props: { title: string }) => {

    return <Wrapper>
        <p>{props.title}</p>
    </Wrapper>
}

export default Divider