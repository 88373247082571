import styled from 'styled-components'
import {ChangeEvent, ReactNode, useState} from 'react'
import {solease_error_red, solease_green, solease_misty_dove} from '../../assets/Colors'
import {checkedIcon, questionMark} from '../../assets/Icons'

const Wrapper = styled('label')<{ fluid: boolean }>`
    text-align: left;
    display: block;
    width: ${props => (props.fluid ? '100%' : '49%')};
`

const InputWrapper = styled('div')<{
    error?: boolean
    correct: boolean
    focus: boolean
}>`
    box-sizing: border-box;
    height: 46px;
    width: 100%;
    border: 1px solid ${solease_misty_dove};
    border-radius: 4px;
    transition: box-shadow ease-in 0.2s;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    ${props => props.error && `border-color: ${solease_error_red};`}
    ${props => props.focus && `border-color: ${solease_green};`}
    img {
        transition: opacity 0.5s;
        height: 20px;
        opacity: 0;
        margin-left: auto;
        margin-right: 10px;
        ${props => props.correct && 'opacity: 1;'}
    }
`

const InputField = styled('input')<{ error?: boolean, disabled?: boolean }>`
    box-sizing: border-box;
    outline: none;
    border: none;
    width: ${props => props.disabled ? '100%' : '80%'};
    background: ${props => props.disabled ? '#F7F7F7' : 'white'};
    height: 100%;
    padding: 6px 12px;
    font-size: 16px;
    font-family: 'MuseoSans', sans-serif;
    border-radius: 4px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    -moz-appearance: textfield;
`

const Info = styled.img`
    opacity: 1 !important;
    filter: none !important;
`

const Unit = styled.div`
    color: #999999;
    margin-left: auto;
    margin-right: 10px;
    width: 140px;
    text-align: right;

    p {
        margin: 0 !important;
    }

    span:last-of-type {
        display: none;
    }

    @media (max-width: 600px) {
        span:last-of-type {
            display: inline-block;
        }

        span:first-of-type {
            display: none;
        }
    }
`

const LabelTitle = styled.p`
    margin-bottom: 6px !important;
    margin-top: 11px;
    height: 24px;
    display: flex;
    align-items: center;

    ${Info} {
        margin-left: 5px;
    }
`

interface InputPropsInterface {
    value: string | number
    error?: boolean
    placeholder?: string
    label?: string
    correct?: boolean
    fluid?: boolean
    numberType?: boolean
    tooltip?: string
    unit?: ReactNode
    maxLength?: number
    disabled?: boolean

    onBlur?(): void

    onChange(value: string | number): void
}

const Input = (props: InputPropsInterface) => {
    const {value, error, onChange, placeholder, label, unit, maxLength, disabled} =
        props

    const [focus, setFocus] = useState(false)
    const preOnchangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
        const targetValue = event.target.value
        if (props.numberType) {
            ;/^[0-9]*$/.test(targetValue) &&
            targetValue.length < 13 &&
            onChange(targetValue)
        } else onChange(targetValue)
    }

    return (
        <Wrapper fluid={!!props.fluid}>
            {label && (
                <LabelTitle>
                    {label}
                    {props.tooltip && (
                        <Info data-tip={props.tooltip} alt='' src={questionMark}/>
                    )}
                </LabelTitle>
            )}
            <InputWrapper error={error} correct={!!props.correct} focus={focus}>
                <InputField
                    onFocus={() => setFocus(true)}
                    onBlur={() => {
                        setFocus(false)
                        props.onBlur && props.onBlur()
                    }}
                    value={value}
                    placeholder={placeholder}
                    maxLength={maxLength}
                    onChange={preOnchangeFilter}
                    disabled={disabled}
                />
                {unit ? (
                    <Unit>{unit}</Unit>
                ) : (
                    props.correct && <img alt='' src={checkedIcon}/>
                )}
            </InputWrapper>
        </Wrapper>
    )
}

export {Input}
