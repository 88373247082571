import styled from 'styled-components'
import {Checkbox} from '../Shared'
import {useContext} from 'react'
import {LanguageContext} from '../../../utils/Context/LanguageContext'
import {solease_dark_gray, solease_green, solease_misty_dove} from '../../../assets/Colors'

const Wrapper = styled('div')<{ small: boolean; active: boolean }>`
    height: 65px;
    width: 350px;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid ${props => (props.active ? solease_green : solease_misty_dove)};
    box-shadow: 0 4px 4px rgba(0, 0, 0, ${props => (props.active ? 0.1 : 0)});
    transition: all .3s;
    outline: 2px solid transparent;
    outline-color: ${props => props.active ? solease_green : 'transparent'};

    &:hover{
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
    
    ${Checkbox}{
        margin-top: 10px;
        margin-right: 8px;
        border-color: transparent;
    }

    img {
        height: 45px;
    }


    p {
        margin: 0;
        align-self: center;
    }
`

const TextGroup = styled.div`
    text-align: left;

    p {
        font-family: Quicksand, sans-serif;
        margin: 0 !important;
        :nth-child(1) {
            font-size: 24px;
            font-weight: 400;
        }

        :nth-child(2) {
            font-size: 12px;
            color: ${solease_dark_gray};
        }
    }
`

interface RectCheckboxGroupInterface {
    id?: string
    imgSrc: string
    description: string
    active: boolean
    small?: boolean
    highlightCount?: number

    onClick(): void
}

const RectCheckboxGroup = (props: RectCheckboxGroupInterface) => {
    const {strings} = useContext(LanguageContext)

    return (
        <Wrapper
            active={props.active}
            onClick={props.onClick}
            small={!!props.small}
        >
            <Checkbox active={props.active}/>
            <img alt='' src={props.imgSrc}/>
            <TextGroup>
                <p>{strings[props.description as keyof typeof strings]}</p>
                <p>{props.id === 'heatpump' || props.id === 'insulation' ? strings.purchase_only_possibility : strings.rent_purchase_possibility}</p>
            </TextGroup>
        </Wrapper>
    )
}

export default RectCheckboxGroup
