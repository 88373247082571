import RectCheckboxControl from "../components/RectCheckbox/RectCheckboxControl";
import {energyOptions, insulationOptions} from "../../utils/static";
import React, {useContext, useEffect, useState} from "react";
import {LanguageContext} from "../../utils/Context/LanguageContext";
import Divider from "../components/Divider";
import CheckboxControl, {CheckboxControlWrapper} from "../components/CheckboxControl";
import {Button, FooterButtonWrapper, InputsWrapper} from "../components/Shared";
import StyledDropdown from "../components/StyledDropdown";
import {Textarea} from "../components/Textarea";
import {OptionInterface, RectOptionInterface} from "../../utils/interfaces";
import {MainFrameContext} from "../components/MainFrame";
import {StepperContext} from "../../utils/Context/StepperContext";
import {DataContext} from "../../utils/Context/DataContext";
import styled from "styled-components";
import {Input} from "../components/Input";
import GroupLabel from "../components/GroupLabel";

const Wrapper = styled.div`
    ${CheckboxControlWrapper} {
        margin: 50px 0;
    }
`


const Step2 = () => {

    const {strings} = useContext(LanguageContext)
    const {autoResize} = useContext(MainFrameContext)
    const {dispatchStep} = useContext(StepperContext)
    const {step2Submit, isMkb} = useContext(DataContext)

    const [options, setOptions] = useState<RectOptionInterface[]>(energyOptions)
    const [optionsError, setOptionsError] = useState(false)
    const [insulation, setInsulation] = useState<OptionInterface[]>(insulationOptions)
    const [insulationError, setInsulationError] = useState(false)
    const [contractOptions, setContractOptions] = useState<{
        id: string,
        description: string,
        active?: boolean
        disabled?: boolean
    }[]>([{id: 'rent', description: 'label_rent', disabled: true}, {id: 'purchase', description: 'label_purchase'}])
    const [contractOptionsError, setContractOptionsError] = useState(false)
    const [heatingType, setHeatingType] = useState('')
    const [heatingTypeError, setHeatingTypeError] = useState(false)
    const [houseType, setHouseType] = useState('')
    const [houseTypeError, setHouseTypeError] = useState(false)
    const [comments, setComments] = useState('')
    const [consumption, setConsumption] = useState(0)

    useEffect(() => {
        const rentAvailable =
            options.some(option =>
                ['solar', 'homebattery', 'charging_station'].includes(option.id) && option.active
            ) || options.every(option => !option.active);

        setContractOptions([
            {id: 'rent', description: 'label_rent', disabled: !rentAvailable},
            contractOptions[1],
        ]) // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])

    useEffect(() => {
        autoResize()
    }, [options, autoResize])

    const onSubmit = () => {
        if (!validateFields()) {
            step2Submit({
                options,
                insulation,
                contractOptions,
                heatingType,
                houseType,
                consumption,
                comments
            })
        }
    }

    const validateFields = () => {
        let hasErrors = false

        const hasSelectedOption = !!options.filter(option => option.active).length
        const hasSelectedContractOption = !!contractOptions.filter(option => option.active).length
        const insulationOptionSelected = options.find(option => option.id === 'insulation')?.active
        const hasSelectedInsulation = insulation.filter(option => option.active).length
        const heatpumpOptionSelected = options.find(option => option.id === 'heatpump')?.active

        if (!hasSelectedOption) {
            setOptionsError(true)
            hasErrors = true
        }
        if (!hasSelectedContractOption) {
            setContractOptionsError(true)
            hasErrors = true
        }
        if (insulationOptionSelected && !hasSelectedInsulation) {
            setInsulationError(true)
            hasErrors = true
        }
        if (heatpumpOptionSelected && !heatingType) {
            setHeatingTypeError(true)
            hasErrors = true
        }
        if (heatpumpOptionSelected && !houseType) {
            setHouseTypeError(true)
            hasErrors = true
        }
        return hasErrors
    }

    return (
        <Wrapper>
            <RectCheckboxControl
                label={{description: 'Over welke producten zou je meer willen weten?'}}
                options={options}
                error={optionsError}
                onChange={(options) => {
                    setOptionsError(false)
                    setInsulationError(false)
                    setOptions(options)
                }}/>

            {
                options.find(option => option.id === 'insulation')?.active &&
                <>
                    <Divider title={'Isolatie'}/>
                    <CheckboxControl
                        label={{description: 'In welk type isolatie ben je geïnteresseerd?'}}
                        options={insulation}
                        error={insulationError}
                        onChange={(options) => {
                            setInsulation(options)
                            setInsulationError(false)
                        }}/>
                </>
            }

            {
                options.find(option => option.id === 'heatpump')?.active &&
                <>
                    <Divider title={'Warmtepomp'}/>
                    <InputsWrapper>
                        <StyledDropdown
                            fluid={true}
                            label={'Wat voor soort verwarmingssysteem heb je?'}
                            value={heatingType}
                            error={heatingTypeError}
                            options={[
                                {label: 'label_heating_type_radiators', value: 'Radiators'},
                                {label: 'label_heating_type_underfloor', value: 'Vloerverwarming'},
                                {
                                    label: 'label_heating_type_radiators_underfloor',
                                    value: 'Radiatoren & Vloerverwarming'
                                },
                                {label: 'label_heating_type_other', value: 'Anders (?)'}]}
                            onChange={(option) => {
                                setHeatingTypeError(false)
                                setHeatingType(option.value)
                            }}/>

                        <StyledDropdown
                            fluid={true}
                            label={'Wat voor type woning heb je?'}
                            value={houseType}
                            error={houseTypeError}
                            options={[
                                {label: 'housetype_terraced_house', value: 'terraced_house'},
                                {label: 'housetype_corner_house', value: 'corner_house'},
                                {label: 'housetype_2_under_1_roof', value: 'under_1_roof'},
                                {label: 'housetype_detached_house', value: 'detached_house'},
                                {label: 'housetype_appartment', value: 'appartment'},
                                {label: 'housetype_upstairs_appartment', value: 'upstairs_appartment'},
                                {label: 'housetype_downstairs_appartment', value: 'downstairs_appartment'}
                            ]}
                            onChange={(option) => {
                                setHouseTypeError(false)
                                setHouseType(option.value)
                            }}/>
                    </InputsWrapper>
                </>
            }

            <CheckboxControl
                label={{description: 'Voor welk type aanbod zou je een offerte willen ontvangen?'}}
                options={contractOptions}
                error={contractOptionsError}
                onChange={options => {
                    setContractOptionsError(false)
                    setInsulationError(false)
                    setContractOptions(options)
                }}/>

            {
                isMkb &&
                <div style={{margin: '50px 0'}}>
                    <GroupLabel label={'Wat is je jaarverbruik in kWh? (niet verplicht)'}/>
                    <Input
                        value={consumption}
                        onChange={(value) => setConsumption(Number(value))}/>
                </div>
            }

            <Textarea
                label={'Heb je nog overige opmerkingen? '}
                value={comments}
                onChange={setComments}/>

            <FooterButtonWrapper>
                <Button onClick={() => dispatchStep('back')}>
                    {strings.button_back}
                </Button>
                <Button primary onClick={onSubmit}>
                    {strings.button_next}
                </Button>
            </FooterButtonWrapper>

        </Wrapper>
    )
}

export default Step2