import styled from 'styled-components'
import { solease_gray } from '../../assets/Colors'

export const GroupLabelWrapper = styled('div')<{
    flex: boolean
    center?: boolean
    secondaryLabelColor?: string
}>`
    margin-bottom: 20px;
    text-align: ${props => (props.center ? 'center' : 'left')};
    display: ${props => (props.flex ? 'flex' : 'block')};

    p {
        width: 100%;
        margin: 0 !important;
        margin-right: 10px !important;

        &:nth-child(1) {
            font-family: Quicksand, sans-serif;
            font-size: 20px;
            font-weight: 500;
        }

        &:nth-child(2) {
            font-size: 16px;
            margin-top: 6px !important;
            color: ${solease_gray};
        }
    }

    @media (max-width: 600px) {
        p {
            font-size: 16px;

            &:nth-child(1) {
                font-size: 19px;
            }

            &:nth-child(2) {
                font-size: 14px;
            }
        }
    }
`


const GroupLabel = (props: {
    label: string
    secondaryLabel?: string
    tooltip?: string
    center?: boolean
}) => (
    <GroupLabelWrapper flex={!props.secondaryLabel} center={props.center}>
        <p>{props.label}</p>
        {props.secondaryLabel && <p>{props.secondaryLabel}</p>}
    </GroupLabelWrapper>
)

export default GroupLabel
