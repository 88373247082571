import React, {
    useCallback,
    useReducer,
} from 'react'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        dataLayer: any
    }
}

interface StepperContextInterface {
    step: number
    dispatchStep(type: string, choice?: string): void
}

export const StepperContext = React.createContext({} as StepperContextInterface)

const StepperContextProvider = (props: { children: React.ReactNode }) => {

    const reducer = useCallback(
        (step: number, action: { type: string; choice?: string }) => {

            switch (action.type) {
                case 'next':
                    return step + 1
                case 'back':
                    return step - 1
                case 'start':
                    return 1
                default:
                    throw new Error()
            }
        },
        []
    )

    const [step, dispatch] = useReducer(reducer, 1)


    const dispatchStep = useCallback(
        (type: string, choice?: string) => dispatch({ type, choice }),
        []
    )


    return (
        <StepperContext.Provider
            value={{
                step,
                dispatchStep,
            }}
        >
            {props.children}
        </StepperContext.Provider>
    )
}

export default StepperContextProvider
