import styled, { css } from 'styled-components'
import 'react-dropdown/style.css'
import {
    solease_dark_gray,
    solease_error_red,
    solease_green
} from '../../assets/Colors'
import { checkboxChecked } from '../../assets/Icons'

const StepTitle = styled.p`
    font-size: 32px;
    margin-bottom: 30px;
    color: ${solease_green};
    font-family: Quicksand, sans-serif;
    font-weight: 600;

    @media (max-width: 600px) {
        font-size: 22px;
    }
`

const Text = styled('p')<{left?: boolean, small?: boolean}>`
    font-size: ${props => props.small ? '14px' : '18px'};
    text-align: ${props => props.left ? 'left' : 'inherit'};
    white-space: pre-line;
    
    a{
        text-decoration: underline;
        color: ${solease_green};
    }
    
    @media (max-width: 600px) {
        font-size: 16px;
    }
`

const Button = styled('span')<{
    primary?: boolean
    secondary?: boolean
    fluid?: boolean
}>`
    display: table;
    text-align: center;
    box-sizing: border-box;
    font-family: Quicksand, sans-serif;
    font-weight: 500;
    min-width: ${props => (props.fluid ? '100%' : '173px')};
    padding: 10px 20px;
    height: 42px;
    border-radius: 4px;
    font-size: 24px;
    cursor: pointer;
    color: ${solease_green};

    ${props =>
        (props.primary || props.secondary) &&
        css`
            background: ${props.secondary ? solease_dark_gray : solease_green};
            color: white;
        `};

    @media (max-width: 600px) {
        min-width: ${props => (props.fluid ? '100%' : '148px')};
    }
`

const InputsWrapper = styled('div')<{ mobileFluid?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 11px;

    @media (max-width: 600px) {
        ${props =>
            props.mobileFluid &&
            css`
                margin-bottom: 0;

                label {
                    width: 100%;
                }

                div {
                    margin-bottom: 10px;
                }
            `}
    }
`

const FooterButtonWrapper = styled.div`
    display: flex;
    justify-self: flex-start;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-top: 48px;

    span:last-of-type {
        margin-left: auto;
    }

    @media (max-width: 600px) {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 20px;

        span:last-of-type {
            margin-left: unset;
        }
    }
`
const Checkbox = styled('div')<{ active?: boolean, disabled?: boolean }>`
    position: relative;
    width: 21px;
    height: 21px;
    border: 1px solid #bfbbbb;
    border-radius: 4px;
    font-size: 12px;
    text-align: center;
    transition: all 0.3s;
    
    opacity: ${props => props.disabled ? 0.3 : 1};
    background: ${props => props.disabled ? solease_dark_gray : 'transparent'};

    &:after {
        position: absolute;
        top: -3px;
        left: 2px;
        content: url(${checkboxChecked});
        opacity: ${props => (props.active ? 1 : 0)};
        transition: all 0.3s;

        @media (max-width: 600px) {
            width: 13px;
            height: 10px;
        }
    }
`

const Disclaimer = styled.div`
    font-size: 10px;
    font-style: italic;
    text-align: justify;
    margin-top: 15px;
    line-height: 16px;
`

const GroupedImagesSection = styled.div`
    display: flex;
    width: 100%;
    min-height: 200px;
    justify-content: center;
    gap: 30px;

    span {
        width: 170px;
    }

    p {
        margin: 0 !important;
        font-size: 12px;
        white-space: pre-line;
        line-height: normal;
        
        &:nth-child(1){
            color: ${solease_green};
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        
        div {
            display: flex;
        }
        
        img{
            width: 70px;
            height: 70px;
        }
        
        span{
            width: unset;
            
            p{
                text-align: left;

                &:nth-child(1){
                    margin-bottom: 5px !important;
                }
            }
        }
    }
`

const CheckboxGroup = styled('label')<{ error?: boolean }>`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
    text-align: left;
    margin-top: 30px;

    ${Checkbox} {
        min-width: 17px;
        width: 17px;
        height: 17px;
        background: white;

        ${props => props.error && `border-color: ${solease_error_red};`}
        &:after {
            left: 0;
            transform: scale(0.8);
        }
    }

    & p {
        margin-left: 6px;
        margin-bottom: 0 !important;
        margin-top: 0 !important;
        font-size: 14px;
        font-weight: 300;
    }

    a {
        text-decoration: none;
        color: ${solease_green};
    }

    img {
        width: 25px;
        height: 25px;
        margin-left: 10px;
    }

    @media (max-width: 600px) {
        width: 100%;
        margin: auto;
        p,
        a {
            text-align: left;
        }

        input {
            transform: scale(1.5);
            margin-left: 10px;
        }
    }
`

export {
    Text,
    StepTitle,
    Button,
    FooterButtonWrapper,
    InputsWrapper,
    Checkbox,
    Disclaimer,
    GroupedImagesSection,
    CheckboxGroup
}
