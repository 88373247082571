import styled from 'styled-components'
import {
    solease_green,
    solease_misty_dove
} from '../../assets/Colors'

const Wrapper = styled.label`
    text-align: left;
    display: block;
    width: 100%;
`

const StyledTextarea = styled('textarea')<{ focus?: boolean }>`
    width: 100%;
    box-sizing: border-box;
    height: 74px;
    resize: none;
    margin-top: 11px;
    transition: box-shadow ease-in 0.2s;
    border: 1px solid ${solease_misty_dove};
    border-radius: 4px;

    &:focus {
        outline: none;
        border-color: ${solease_green};
    }

    padding: 6px 8px;
    font-size: 16px;
    font-family: 'MuseoSans', sans-serif;
`

const LabelTitle = styled.p`
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 6px !important;
    margin-top: 11px;
`

interface TextareaPropsInterface {
    value: string
    label?: string

    onChange(value: string): void
}

const Textarea = (props: TextareaPropsInterface) => (
    <Wrapper>
        <LabelTitle>{props.label}</LabelTitle>
        <StyledTextarea
            value={props.value}
            onChange={e => props.onChange(e.target.value)}
        />
    </Wrapper>
)

export { Textarea }
