import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './assets/styles/global.css'
import StepperContextProvider from "./utils/Context/StepperContext";

window.renderOfferForm = (config: { rootId: string }) => {

    const root = ReactDOM.createRoot(
        document.getElementById(config.rootId) as HTMLElement
    )
    root.render(
        <StepperContextProvider>
            <App/>
        </StepperContextProvider>
    )
}

if (
    process.env.REACT_APP_RENDER_ON?.split(' ').includes(
        window.location.hostname
    )
) {
    window.renderOfferForm({rootId: 'root'})
}
