const getHost = () => window.location.host.replace('www.', '')

const extractAdditionFromHouseNumber = (houseNumber: string) => {
    for (let i = 0; i < houseNumber.length; i++) {
        if (houseNumber[i].match(/[A-Z]/i)) {
            return [houseNumber.slice(0, i), houseNumber.slice(i)]
        }
    }

    return [houseNumber]
}

export {
    getHost,
    extractAdditionFromHouseNumber,
}
