import styled, {css} from 'styled-components'
import {useContext, useRef, useState} from 'react'
import useOutsideAlerter from '../../utils/CustomHooks/OutsideAlerter'
import {solease_cultured, solease_error_red, solease_gray_2, solease_misty_dove} from '../../assets/Colors'
import {arrowIcon} from '../../assets/Icons'
import {LanguageContext} from "../../utils/Context/LanguageContext";

export const StyledDropdownWrapper = styled('div')<{ fluid?: boolean; hidden?: boolean; ref?: any }>`
    display: ${props => (props.hidden ? 'none' : 'block')};
    width: ${props => (props.fluid ? '100%' : '49%')};
    position: relative;
    text-align: left;
`

const DropdownControl = styled('div')<{ error?: boolean }>`
    box-sizing: border-box;
    text-align: left;
    width: 100%;
    height: 46px;
    padding: 6px 12px;
    transition: box-shadow ease-in 0.2s;
    background-color: white;
    display: ${props => (props.hidden ? 'none' : 'flex')};
    align-items: center;
    justify-content: flex-start;
    position: relative;
    font-size: 16px;
    font-family: 'MuseoSans', sans-serif;
    border: 1px solid ${solease_misty_dove};
    border-radius: 4px;
    ${props => props.error && `border-color: ${solease_error_red};`};
`

const DropdownMenu = styled.div`
    border: none;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
    background-color: ${solease_cultured};
    position: absolute;
    width: 100%;
    z-index: 10;

    div {
        display: flex;
        align-items: center;
        padding: 10px 15px;
        background-color: white;
        border-top: 1px solid ${solease_gray_2};
        transition: all 0.2s;
        cursor: pointer;

        &:hover {
            background-color: ${solease_cultured};
        }
    }
`

const Arrow = styled('img')<{ open?: boolean }>`
    margin-left: auto;
    display: inline-block;
    transition: all 0.2s;

    ${props =>
            props.open &&
            css`
                transform: rotate(180deg);
            `}
`

const LabelTitle = styled('p')`
    margin-bottom: 6px !important;
    margin-top: 11px;
    font-family: Quicksand, sans-serif;
    font-weight: 500;
    font-size: 16px;
`

const Placeholder = styled.p`
    color: #777;

    @media (max-width: 600px) {
        font-size: 12px;
    }
`
const DropdownIcon = styled.img`
    width: 15px;
    margin-left: 10px;
    margin-right: 20px;
`

interface OptionInterface {
    label: string
    value: string
}

interface StyledDropdownInterface {
    label?: string
    error?: boolean
    value?: any
    options: OptionInterface[]
    fluid?: boolean
    hidden?: boolean
    placeholder?: string
    icon?: string

    onChange(option: OptionInterface): void
}

const StyledDropdown = (props: StyledDropdownInterface) => {
    const {options, label, fluid, error, value, hidden, onChange} = props

    const [isOpen, setIsOpen] = useState(false)
    const [selectedDropValue, setSelectedDropValue] = useState<OptionInterface | undefined>(options.find(option => option.value === value))
    const {strings} = useContext(LanguageContext)
    const ref = useRef(undefined)
    useOutsideAlerter(ref, () => setIsOpen(false))

    const onOptionClicked = (option: OptionInterface) => {
        setSelectedDropValue(option)
        onChange(option)
        setIsOpen(false)
    }

    return (
        <StyledDropdownWrapper fluid={!!fluid} hidden={hidden} ref={ref}>
            {label && (
                <LabelTitle>{label}</LabelTitle>
            )}
            <DropdownControl onClick={() => setIsOpen(!isOpen)} error={error}>
                {props.icon && <DropdownIcon src={props.icon} alt={''}/>}
                {
                    selectedDropValue && strings[selectedDropValue.label]
                }
                {!selectedDropValue && (
                    <Placeholder>{props.placeholder}</Placeholder>
                )}
                <Arrow open={isOpen} src={arrowIcon} alt={''}/>
            </DropdownControl>
            {isOpen && (
                <DropdownMenu>
                    {options.map((option, index) => (
                        <div
                            key={index}
                            onClick={() => onOptionClicked(option)}
                        >
                            {props.icon && (
                                <DropdownIcon src={props.icon} alt={''}/>
                            )}
                            {strings[option.label]}
                        </div>
                    ))}
                </DropdownMenu>
            )}
        </StyledDropdownWrapper>
    )
}

export default StyledDropdown
