import React, {useContext, useState} from "react";
import {LanguageContext} from "../../utils/Context/LanguageContext";

import {Button, Checkbox, CheckboxGroup, FooterButtonWrapper, InputsWrapper} from "../components/Shared";
import {Input} from "../components/Input";
import StyledDropdown from "../components/StyledDropdown";
import GroupLabel from "../components/GroupLabel";
import {DataContext} from "../../utils/Context/DataContext";
import {StepperContext} from "../../utils/Context/StepperContext";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

const Tooltip = styled(ReactTooltip)`
    font-size: 10px;
    font-weight: 300;
`

const Step3 = () => {

    const {strings} = useContext(LanguageContext)
    const {submitOfferForm, isMkb} = useContext(DataContext)
    const {dispatchStep} = useContext(StepperContext)

    const [companyName, setCompanyName] = useState('')
    const [companyNameError, setCompanyNameError] = useState(false)
    const [salutation, setSalutation] = useState('')
    const [firstname, setFirstName] = useState('')
    const [firstnameError, setFirstNameError] = useState(false)
    const [lastname, setLastName] = useState('')
    const [lastnameError, setLastNameError] = useState(false)
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [phone, setPhone] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [fieldsEmptyError, setFieldsEmptyError] = useState(false)
    const [fieldsInvalidError, setFieldsInvalidError] = useState(false)
    const [checkbox, setCheckbox] = useState(false)

    const setCompanyNameField = (value : string) => {
        setCompanyName(value)
        !value ? setCompanyNameError(true) : setCompanyNameError(false)
    }

    const setFirstNameField = (value: string) => {
        setFirstName(value)
        !value ? setFirstNameError(true) : setFirstNameError(false)
        fieldsInvalidError && setFieldsInvalidError(false)
        fieldsEmptyError && setFieldsEmptyError(false)
    }

    const setLastNameField = (value: string) => {
        value.length < 32 && setLastName(value)
        !value ? setLastNameError(true) : setLastNameError(false)
        fieldsInvalidError && setFieldsInvalidError(false)
        fieldsEmptyError && setFieldsEmptyError(false)
    }

    const setEmailField = (value: string) => {
        const emailRegex = /^[\w-.]+[0-9a-z+-]+@([\w-]+\.)+[\w-]{2,4}$/
        setEmail(value)
        emailRegex.test(value) ? setEmailError(false) : setEmailError(true)
        fieldsInvalidError && setFieldsInvalidError(false)
        fieldsEmptyError && setFieldsEmptyError(false)
    }

    const setPhoneField = (value: string) => {
        if (/^[^a-zA-Z]+$/g.test(value) || !value) {
            const phoneRegex =
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/
            value.length < 13 && setPhone(value)
            phoneRegex.test(value) ? setPhoneError(false) : setPhoneError(true)
            fieldsInvalidError && setFieldsInvalidError(false)
            fieldsEmptyError && setFieldsEmptyError(false)
        }
    }

    const validateFields = () => {
        let hasErrors = false
        if(isMkb && !companyName){
            setCompanyNameError(true)
            hasErrors = true
        }
        if (!firstname) {
            setFirstNameError(true)
            hasErrors = true
        }
        if (!lastname) {
            setLastNameError(true)
            hasErrors = true
        }
        if (!/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            setEmailError(true)
            hasErrors = true
        }
        if (!/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(phone)) {
            setPhoneError(true)
            hasErrors = true
        }

        return hasErrors
    }

    const onSubmit = () => {
        if (!validateFields()) {
            submitOfferForm({firstname, lastname, email, phone, salutation}, companyName)
        }
    }

    return (
        <>

            <GroupLabel label={'Met wie hebben wij het genoegen?'}/>

            {
                isMkb &&
                <Input
                    fluid
                    label={'Bedrijfsnaam'}
                    value={companyName}
                    error={companyNameError}
                    onChange={setCompanyNameField}/>
            }

            <StyledDropdown
                label={'Aanhef'}
                options={[
                    {label: 'salutation_mr', value: 'dhr.'},
                    {label: 'salutation_mrs', value: 'mevr.'},
                    {label: 'salutation_customer', value: 'klant'},
                    {label: 'salutation_hidden', value: 'zeg ik liever niet'}]}
                onChange={(option) => setSalutation(option.value)}/>

            <InputsWrapper mobileFluid>
                <Input
                    error={firstnameError}
                    value={firstname}
                    correct={firstname !== ''}
                    onChange={setFirstNameField}
                    label={strings.firstname_placeholder}
                />
                <Input
                    error={lastnameError}
                    value={lastname}
                    correct={lastname !== ''}
                    onChange={setLastNameField}
                    label={strings.lastname_placeholder}
                />
            </InputsWrapper>

            <InputsWrapper mobileFluid>
                <Input
                    error={emailError}
                    value={email}
                    onChange={setEmailField}
                    correct={/^[\w-.]+[0-9a-z]+@([\w-]+\.)+[\w-]{2,4}$/.test(
                        email
                    )}
                    label={strings.email_placeholder}
                />
                <Input
                    error={phoneError}
                    value={phone}
                    tooltip={'Om tot een goed advies te komen hebben we een aantal gegevens van je nodig. We nemen telefonisch contact met je op om een aantal vragen te stellen over jouw behoeften en een aantal praktische zaken'}
                    onChange={setPhoneField}
                    correct={/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                        phone
                    )}
                    label={strings.phone_placeholder}
                />
            </InputsWrapper>

            {
                !isMkb &&
                <CheckboxGroup
                    onClick={() => setCheckbox(!checkbox)}
                >
                    <Checkbox active={checkbox}/>
                    <p>
                        Ja, stuur mij nieuws over Solease, zonnepanelen, marktontwikkelingen of verwante
                        producten/diensten
                        die mogelijk relevant zijn voor mij. Zie ook onze&nbsp;
                        <a
                            target='_blank'
                            rel='noreferrer'
                            href='https://www.solease.nl/privacy'
                        >
                            Privacyverklaring
                        </a>
                    </p>
                </CheckboxGroup>
            }

            <FooterButtonWrapper>
                <Button onClick={() => dispatchStep('back')}>
                    {strings.button_back}
                </Button>
                <Button primary onClick={onSubmit}>
                    {strings.button_next_step_3}
                </Button>
            </FooterButtonWrapper>

            <Tooltip
                multiline={true}
                place='right'
                type='dark'
                effect='solid'
            />
        </>
    )
}

export default Step3