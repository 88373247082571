import {Button, FooterButtonWrapper, InputsWrapper, StepTitle, Text} from "../components/Shared";
import React, {useContext, useEffect, useState} from "react";
import {Input} from "../components/Input";
import StyledDropdown from "../components/StyledDropdown";
import {DataContext} from "../../utils/Context/DataContext";
import {StepperContext} from "../../utils/Context/StepperContext";
import styled from "styled-components";
import {frownIcon} from "../../assets/Icons";
import FormTypeSwitch from "../components/FormTypeSwitch";

const OwnerError = styled.div`
    display: flex;
    font-family: Quicksand, sans-serif;

    p {
        font-size: 16px;
        text-align: left;
        margin-left: 10px;
    }
`

const Step1 = () => {

    const queryParams = new URLSearchParams(window.location.search)

    const {setLocationData, location, houseOwner, setHouseOwner, isMkb} = useContext(DataContext)
    const {dispatchStep} = useContext(StepperContext)

    const [address, setAddress] = useState(location.number || queryParams.get('housenumber') || '')
    const [addressError, setAddressError] = useState(false)
    const [postCode, setPostCode] = useState(location.postcode || queryParams.get('zip') || '')
    const [postCodeError, setPostCodeError] = useState(false)
    const [houseTypeError, setHouseTypeError] = useState(false)

    const ownerError = houseOwner === '2' || houseOwner === '3' || houseOwner === '4'
    const isCompanyOwner = houseOwner === '5'

    const houseOwnerOptions = [
        {label: 'houseowner_option_house_1', value: '1'},
        {label: 'houseowner_option_house_2', value: '2'},
        {label: 'houseowner_option_rent_1', value: '3'},
        {label: 'houseowner_option_apartment_1', value: '4'}
    ]

    const mkbOwnerOptions = [
        {label: 'mkb_option_1', value: '1'},
        {label: 'mkb_option_2', value: '2'},
        {label: 'mkb_option_3', value: '3'},
        {label: 'mkb_option_4', value: '4'},
        {label: 'mkb_option_5', value: '5'},
        {label: 'mkb_option_6', value: '6'}
    ]

    const setAddressField = (value: string) => {
        if (value) {
            value.length < 8 &&
            value.match(/^\d+[a-zA-Z]*$/) &&
            setAddress(value.toLocaleUpperCase())
            addressError && setAddressError(false)
        } else {
            setAddress(value)
            setAddressError(true)
        }
    }

    const setPostCodeField = (value: string) => {
        if (value) {
            postCodeError && setPostCodeError(false)
            value.length < 5
                ? value[value.length - 1].match(/^\d+$/) !== null &&
                setPostCode(value)
                : value.length < 7 &&
                value[value.length - 1].match(/[a-z]/i) &&
                setPostCode(value.toLocaleUpperCase())
        } else {
            setPostCode(value)
            setPostCodeError(true)
        }
    }

    const fetchLocationData = () => {
        if (address && postCode) {
            setLocationData(address, postCode)
        }
    }

    const validateFields = () => {
        let hasErrors = false
        if (!address) {
            setAddressError(true)
            hasErrors = true
        }
        if (postCode.length !== 6) {
            setPostCodeError(true)
            hasErrors = true
        }
        if (!isMkb && !houseOwner) {
            setHouseTypeError(true)
            hasErrors = true
        }
        if (ownerError || isCompanyOwner) {
            hasErrors = true
        }
        return hasErrors
    }

    const onSubmit = () => {
        if (!validateFields()) {
            dispatchStep('next')
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fetchLocationData, [setLocationData])

    return (
        <>
            <FormTypeSwitch/>

            {!isMkb ?
                <>
                    <StepTitle>Woning verduurzamen?</StepTitle>
                    <Text style={{margin: '-25px 0 48px 0', fontSize: '16px'}}>Vraag geheel vrijblijvend een offerte
                        aan</Text>
                </>
                : <StepTitle style={{marginBottom: '48px'}}>Bedrijfspand verduurzamen?</StepTitle>}


            <Text left={true}>Vul hier je postcode en huisnummer in</Text>

            <InputsWrapper>
                <Input
                    onChange={setPostCodeField}
                    correct={postCode.length === 6}
                    onBlur={fetchLocationData}
                    value={postCode}
                    error={postCodeError}
                    label={'Postcode'}
                />
                <Input
                    onChange={setAddressField}
                    correct={address !== ''}
                    onBlur={fetchLocationData}
                    value={address}
                    error={addressError}
                    label={'Huisnummer (+toevoeging)'}
                />
            </InputsWrapper>

            <InputsWrapper mobileFluid>
                <Input
                    onChange={() => null}
                    value={location.street}
                    label={'Straat'}
                    disabled
                />
                <Input
                    onChange={() => null}
                    value={location.city}
                    label={'Plaats'}
                    disabled
                />
            </InputsWrapper>

            <InputsWrapper>
                {
                    isMkb ?
                        <StyledDropdown
                            fluid={true}
                            label={'Welk bedrijfspand wil je verduurzamen?'}
                            options={mkbOwnerOptions}
                            value={houseOwner}
                            error={houseTypeError}
                            onChange={(option) => {
                                setHouseTypeError(false)
                                setHouseOwner(option.value)
                            }}/>

                        : <StyledDropdown
                            fluid={true}
                            label={'Wat voor huis heb je ? '}
                            options={houseOwnerOptions}
                            value={houseOwner}
                            error={houseTypeError}
                            onChange={(option) => {
                                setHouseTypeError(false)
                                setHouseOwner(option.value)
                            }}/>
                }
            </InputsWrapper>

            {
                ownerError &&
                <OwnerError>
                    <img src={frownIcon} alt={''}/>
                    <Text>Helaas, we kunnen je geen offerte sturen. Solease brengt alleen offertes uit aan personen die
                        volledig eigenaar zijn van de woning. </Text>
                </OwnerError>
            }

            <FooterButtonWrapper>
                <Button primary secondary={ownerError || isCompanyOwner} onClick={onSubmit}>
                    {'Volgende'}
                </Button>
            </FooterButtonWrapper>

        </>
    )
}

export default Step1
