import {GroupedImagesSection, StepTitle, Text} from '../components/Shared'
import {useContext} from 'react'
import {LanguageContext} from '../../utils/Context/LanguageContext'
import {discussWishesImg, receiveQuoteImg, sharpenAdviceImg} from '../../assets/Icons'
import GroupLabel from "../components/GroupLabel";
import styled from "styled-components";

const MiddleText = styled(Text)`
    margin: 38px 0 !important;
`

const Step4 = () => {
    const {strings} = useContext(LanguageContext)

    return (
        <>
            <StepTitle>{'Geweldig! Je aanvraag is ontvangen '}</StepTitle>
            <Text>{'Je hebt de eerste stap gezet om de wereld te verbeteren! \n Een Consultant Duurzame Energie neemt binnenkort telefonisch contact met je op voor een persoonlijk advies.'}</Text>

            <MiddleText small>
                Isolatie en warmtepomp aanvragen worden doorgestuurd naar onze partner(s). Zij nemen contact met je op.
            </MiddleText>

            <GroupLabel center label={'Wat gaat er nu gebeuren?'}/>

            <GroupedImagesSection>
                <div>
                    <img src={discussWishesImg} alt=''/>
                    <span>
                        <p>{strings.label_discuss_wishes}</p>
                        <p>{strings.description_discuss_wishes}</p>
                    </span>
                </div>
                <div>
                    <img src={sharpenAdviceImg} alt=''/>
                    <span>
                        <p>{strings.label_sharpen_advice}</p>
                        <p>{strings.description_sharpen_advice}</p>
                    </span>
                </div>
                <div>
                    <img src={receiveQuoteImg} alt=''/>
                    <span>
                        <p>{strings.label_receive_quote}</p>
                        <p>{strings.description_receive_quote}</p>
                    </span>
                </div>
            </GroupedImagesSection>
        </>
    )
}

export default Step4
